import clsx from 'clsx';
import React from 'react';

import {
  ESocialMediaIconStyle,
  ESocialMediaType,
} from './SocialMedia.constants';
import { ReactComponent as IconYouko } from '../../../../public/icons/components/socialMedia/Youko.svg';
import { ReactComponent as IconFacebook } from '../../../../public/icons/components/socialMedia/facebook.svg';
import { ReactComponent as IconInstagram } from '../../../../public/icons/components/socialMedia/instagram.svg';
import { ReactComponent as IconLinkedin } from '../../../../public/icons/components/socialMedia/linkedin.svg';
import { ReactComponent as IconTelegram } from '../../../../public/icons/components/socialMedia/telegram.svg';
import { ReactComponent as IconTwitter } from '../../../../public/icons/components/socialMedia/twitter.svg';
import { ReactComponent as IconWeChat } from '../../../../public/icons/components/socialMedia/wechat.svg';
import { ReactComponent as IconWeibo } from '../../../../public/icons/components/socialMedia/weibo.svg';
import { ReactComponent as IconYoutube } from '../../../../public/icons/components/socialMedia/yotube.svg';

export type TSocialMediaProps = {
  socialPlatform: ESocialMediaType;
  currentUrl?: string;
  className?: string;
  iconStyle: ESocialMediaIconStyle;
};

const SocialMedia: React.FC<TSocialMediaProps> = props => {
  const {
    socialPlatform,
    currentUrl,
    iconStyle = ESocialMediaIconStyle.Original,
  } = props;

  const iconClasses = clsx({
    'filter [filter:brightness(0)_saturate(100%)_invert(34%)_sepia(9%)_saturate(317%)_hue-rotate(182deg)_brightness(94%)_contrast(93%)] size-6':
      iconStyle === ESocialMediaIconStyle.Black,
    'h-[1.75rem] w-[1.75rem]': iconStyle === ESocialMediaIconStyle.Original,
  });
  let icon: React.JSX.Element | null;

  switch (socialPlatform) {
    case ESocialMediaType.Facebook:
      icon = <IconFacebook className={iconClasses} />;
      break;
    case ESocialMediaType.Linkedin:
      icon = <IconLinkedin className={iconClasses} />;
      break;
    case ESocialMediaType.Twitter:
      icon = <IconTwitter className={iconClasses} />;
      break;
    case ESocialMediaType.Telegram:
      icon = <IconTelegram className={iconClasses} />;
      break;
    case ESocialMediaType.Instagram:
      icon = <IconInstagram className={iconClasses} />;
      break;
    case ESocialMediaType.Weibo:
      icon = <IconWeibo className={iconClasses} />;
      break;
    case ESocialMediaType.WeChat:
      icon = <IconWeChat className={iconClasses} />;
      break;
    case ESocialMediaType.Youko:
      icon = <IconYouko className={iconClasses} />;
      break;
    case ESocialMediaType.Youtube:
      icon = <IconYoutube className={iconClasses} />;
      break;
    default:
      icon = null;
      break;
  }

  return (
    <div>
      {currentUrl && (
        <a
          className={clsx(
            'group flex cursor-pointer items-center justify-center transition-opacity hover:opacity-70',
            {
              'h-[1.75rem] w-[1.75rem]':
                iconStyle === ESocialMediaIconStyle.Original,
              'size-6': iconStyle === ESocialMediaIconStyle.Black,
            },
          )}
          rel='nofollow noreferrer'
          target='_blank'
          href={currentUrl}
        >
          {icon}
        </a>
      )}
    </div>
  );
};

export default SocialMedia;
